import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bootstrapfader',
  templateUrl: './bootstrapfader.component.html',
  styleUrls: ['./bootstrapfader.component.css']
})
export class BootstrapfaderComponent  {

  @Input() images: any;
  @Input() sliderType: any;
  @Input() sliderID: string;
  constructor() { }



}
