import { Component } from '@angular/core';
declare var jQuery: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tyb1';

 constructor() {
  jQuery(document).ready(function(){
    jQuery('#mainwrapper').css({
      'top':'50%',
      'margin-top':'-540px',
      'left':'50%',
      'margin-left':'-960px'
    });
  });
}
}
