import { Directive } from '@angular/core';
// give angular knowledge bout globals var ofn third-party-scripts
declare var jQuery: any;

@Directive({
  selector: '[appCarousel]'
})
export class CarouselDirective {

  constructor() {
    jQuery(function() {
      return jQuery(".carousel").on("slide.bs.carousel", function(ev) {
        jQuery("img.lazy").lazyload();
      });
    });
    
    jQuery(document).ready(function(){
      jQuery('#carouselExampleSlidesOnly56').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll:1,
        prevArrow: '<img src="assets/left.png" class="lefty">',
        nextArrow: '<img src="assets/right.png" class="righty">'
      });
      jQuery('.homebutton').click( function() {
        jQuery('#fader').hide();
          jQuery('.medium').each( function (){
            jQuery(this).removeClass('mediumactive');
       //    alert( jQuery(this).attr('id') );;
          })
        
            let vid:any = document.getElementById('mainvideo');
            vid.load();
          
      });
     
      jQuery('#mainnav').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll:3,
        prevArrow: '<img src="assets/left.png" class="lefty">',
        nextArrow: '<img src="assets/right.png" class="righty">'
      });
      //alert(jQuery(document).height()); 
     // let topOffset = parseInt( (document.body. - 1080) / 2);
    });
   }

}
 