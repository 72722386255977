import { Component, OnInit, Input, AfterViewInit, AfterContentInit, ElementRef, Renderer } from '@angular/core';
declare var jQuery:any;
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  @Input() itemTitle: any;
  @Input() cssGradient: string;
  @Input() cssMainBG: string;
  @Input() itemToLoad: any;
  assetsTitle:any;
  constructor(private elRef:ElementRef, private rend: Renderer) {

   }
 
  ngOnInit() {
    console.log(this.elRef.nativeElement.children[0])
    this.assetsTitle = this.itemTitle;
    this.rend.setElementStyle(this.elRef.nativeElement.children[0],'background',this.cssMainBG);
    this.rend.setElementStyle(this.elRef.nativeElement.children[0],'background',this.cssGradient);  
 
  }

  ngAfterContentInit() {
    this.assetsTitle = this.itemTitle;
    console.log( this.assetsTitle);
  }
  loadItem(){
    let vid:any = document.getElementById('mainvideo');
    if ( this.itemTitle == "Video") {
      vid.play(); 
    }
    jQuery('#fader').fadeIn(100);
    window.setTimeout( () => { document.getElementById(this.itemToLoad).classList.add('mediumactive');  }, 1000);
    
     
  }
}
