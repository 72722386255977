import { Component, OnInit,Input, AfterContentInit,AfterViewInit } from '@angular/core';
declare var jQuery:any;
@Component({
  selector: 'app-bootstrapcarousel',
  templateUrl: './bootstrapcarousel.component.html',
  styleUrls: ['./bootstrapcarousel.component.css']
})
export class BootstrapcarouselComponent {
  @Input() images: any;
  @Input() sliderType: any;
  @Input() sliderID: string;
  constructor() { }

}
