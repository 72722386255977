import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselDirective } from './directives/carousel.directive';
import { ItemComponent } from './item/item.component';
import { VideoComponent } from './media/video/video.component';
import { HomebuttonComponent } from './media/homebutton/homebutton.component';
import { BootstrapcarouselComponent } from './media/bootstrapcarousel/bootstrapcarousel.component';
import { WebsiteComponent } from './media/website/website.component';
import { BootstrapfaderComponent } from './media/bootstrapfader/bootstrapfader.component';

@NgModule({
  declarations: [
    AppComponent,
    CarouselDirective,
    ItemComponent,
    VideoComponent,
    HomebuttonComponent,
    BootstrapcarouselComponent,
    WebsiteComponent,
    BootstrapfaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
